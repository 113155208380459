'use client'

import clsx from 'clsx'

import { ClipLoader } from '..'
import { Button as CatalystButton } from '../catalyst'

const Button = ({
  loading,
  disabled,
  onClick,
  children,
  ...props
}: React.ComponentProps<typeof CatalystButton> & {
  loading?: boolean
  disabled?: boolean
  onClick?: React.MouseEventHandler<HTMLElement>
}) => {
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <CatalystButton {...props} disabled={loading || disabled} onClick={onClick}>
      <div className={clsx(loading ? 'invisible' : '', 'flex flex-row items-center justify-center')}>{children}</div>
      <div className={clsx(loading ? '' : 'hidden', 'absolute flex flex-row items-center justify-center')}>
        <ClipLoader />
      </div>
    </CatalystButton>
  )
}

export default Button
