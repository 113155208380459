'use client'

import { useCallback, useRef, useState } from 'react'

import { CalculatorIcon, DocumentDuplicateIcon, SparklesIcon } from '@heroicons/react/16/solid'

import {
  Button,
  Card,
  CreateSelfGuidedResourcePlanCard,
  Notification,
  SalaryCalculator,
  Tabs,
  useUnsavedChanges,
  ViewSelfGuidedResourcePlanCard
} from '@/components'
import { Alert, AlertActions, AlertDescription, AlertTitle } from '@/components/catalyst'
import { resourcePlanType } from '@/components/resource-plan/self/schemas'
import { useFetch, useWarnOnRouteChange } from '@/hooks'

import ChatCard from './ChatCard'
import SaveResourcePlansModal from './SaveResourcePlansModal'
import UpdateProjectForm from './UpdateProjectForm'
import { initialAiMessages } from './config'

const tabs = [
  { key: 'ai', name: 'Assistant', icon: SparklesIcon },
  { key: 'calculator', name: 'Calculator', icon: CalculatorIcon },
  { key: 'compare', name: 'Compare Plans', icon: DocumentDuplicateIcon }
]

interface Message {
  role: string
  text?: string
  html?: string
}

interface ChatRef {
  submitUserMessage: (message: { text: string }) => void
}

interface SideView {
  view: 'calculator' | 'ai' | 'compare'
  data: any
}

const SelfGuidedAssistant = ({
  project,
  existingResourcePlan,
  isEditing = true
}: {
  project: Project
  existingResourcePlan?: resourcePlanType
  isEditing?: boolean
}) => {
  const [isEditingResourcePlan, setIsEditingResourcePlan] = useState<boolean>(isEditing)
  const [isFirstEdit, setIsFirstEdit] = useState<boolean>(true)
  const [resourcePlan, setResourcePlan] = useState<resourcePlanType | undefined>(existingResourcePlan || undefined)
  const [copiedResourcePlan, setCopiedResourcePlan] = useState<resourcePlanType>()
  const [showProjectSavedNotification, setShowProjectSavedNotification] = useState<boolean>(false)
  const [assistantChatHistory, setAssistantChatHistory] = useState<Message[]>([...initialAiMessages])
  const [sideViewContent, setSideViewContent] = useState<SideView>({ view: 'ai', data: null })
  const [showSavePlansModal, setShowSavePlansModal] = useState<boolean>(false)
  const [showConfirmCopyAlert, setShowConfirmCopyAlert] = useState<boolean>(false)
  const [hasExistingResourcePlan, setHasExistingResourcePlan] = useState<boolean>(!!existingResourcePlan)

  const chatRef = useRef<ChatRef | null>(null)
  const formRef = useRef<any>(null)

  const { setHasUnsavedChanges } = useUnsavedChanges()
  const { loading: isCreatingResourcePlan, execute: executeCreateResourcePlan } = useFetch()
  const { loading: isSavingResourcePlan, execute: executeSaveResourcePlan } = useFetch()

  useWarnOnRouteChange(
    'You have unsaved changes to your resource plan that will be lost if you do not save your project. Are you sure you want to leave?'
  )

  const handleChange = useCallback((resourcePlan: resourcePlanType) => {
    setResourcePlan(resourcePlan)
  }, [])

  const handleToggleDone = (resourcePlan: resourcePlanType) => {
    if (sideViewContent.view === 'ai') {
      setTimeout(() => {
        chatRef.current?.submitUserMessage({ text: '**Resource Plan Updated**' })
      }, 1000)
    }

    setResourcePlan(resourcePlan)
    setIsEditingResourcePlan(false)
    setIsFirstEdit(false)
  }

  const handleChatRender = (ref: ChatRef) => {
    chatRef.current = ref
  }

  const handleNewMessage = (newMessage: { isHistory: boolean; message: Message }) => {
    const { isHistory, message } = newMessage

    if (isHistory) {
      return
    }

    const messages = assistantChatHistory
    messages.push(message)
    setAssistantChatHistory(messages)
  }

  const saveResourcePlan = async (resourcePlan: resourcePlanType) => {
    let error = ''

    if (hasExistingResourcePlan) {
      const { error: err } = await executeSaveResourcePlan(`/api/projects/${project.id}/resource-plan`, 'PUT', {
        ...resourcePlan
      })

      if (err) {
        error = err
      }
    } else {
      const { error: err } = await executeCreateResourcePlan(`/api/projects/${project.id}/resource-plan`, 'POST', {
        resourcePlan: resourcePlan?.resourcePlan,
        name: resourcePlan?.name,
        selfGuided: true,
        totals: null,
        startDate: resourcePlan?.startDate,
        endDate: resourcePlan?.endDate
      })

      if (err) {
        error = err
      }
    }

    if (error) {
      alert(error)
    } else {
      setShowProjectSavedNotification(true)
      setHasExistingResourcePlan(true)
      setHasUnsavedChanges(false)
    }
  }

  const selectPlansToSave = (resourcePlan: resourcePlanType) => {
    if (copiedResourcePlan) {
      setShowSavePlansModal(true)
    } else {
      saveResourcePlan(resourcePlan)
    }
  }

  const handleSaveProject = () => {
    if (isEditingResourcePlan) {
      formRef.current?.handleSubmit((resourcePlan: resourcePlanType) => selectPlansToSave(resourcePlan))()
    } else if (resourcePlan) {
      selectPlansToSave(resourcePlan)
    }
  }

  const handleCopyPlan = () => {
    setCopiedResourcePlan(JSON.parse(JSON.stringify(resourcePlan)))
    setSideViewContent({ view: 'compare', data: null })
    setShowConfirmCopyAlert(false)
  }

  const handleRevertToComparedPlan = () => {
    const copied = JSON.parse(JSON.stringify(copiedResourcePlan))

    setResourcePlan(copied)
    setCopiedResourcePlan(undefined)

    if (formRef.current) {
      formRef.current.reset(copied)
    }
  }

  const handleCalculatorClick = (data: string) => {
    setSideViewContent({ view: 'calculator', data })
  }

  return (
    <>
      <div className="2xl:flex justify-between !-mb-3">
        <div className="flex items-center justify-between space-x-16 2xl:w-1/2 2xl:pr-4">
          {!existingResourcePlan && <UpdateProjectForm project={project} />}
          <Button
            className="smet-primary-button sm:h-fit sm:w-fit self-end flex-shrink-0"
            onClick={handleSaveProject}
            loading={isCreatingResourcePlan || isSavingResourcePlan}
          >
            <span>Save Project</span>
            <svg
              className="h-5 w-5 sm:h-4 sm:w-4 ml-2"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="white"
            >
              <path d="M18 2.25c.2 0 .39.08.53.22l3 3c.14.14.22.33.22.53v15c0 .41-.34.75-.75.75H3a.75.75 0 0 1-.75-.75V3c0-.41.34-.75.75-.75h15ZM17 12H7a1 1 0 0 0-1 1v7.25h12V13a1 1 0 0 0-1-1Zm-.5-8.25h-9V8a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3.75Zm-2.25.75c.41 0 .75.34.75.75v1.5a.75.75 0 1 1-1.5 0v-1.5c0-.41.34-.75.75-.75Z" />
            </svg>
          </Button>
        </div>
        <div className="hidden 2xl:block">
          <div>
            <Tabs
              tabs={tabs}
              current={sideViewContent.view}
              handleChange={(tab) => setSideViewContent({ view: tab as typeof sideViewContent.view, data: null })}
            />
          </div>
        </div>
      </div>
      <div className="space-y-4 2xl:space-y-0 2xl:flex">
        <div className="2xl:w-1/2 2xl:pr-4">
          {isEditingResourcePlan ? (
            <CreateSelfGuidedResourcePlanCard
              ref={formRef}
              resourcePlan={resourcePlan}
              primaryButtonText={isFirstEdit ? 'Done' : 'Update'}
              footerText="Working Plan"
              handleDone={handleToggleDone}
              handleChange={handleChange}
              handleDirty={(isDirty: boolean) => setHasUnsavedChanges(isDirty)}
              onCalculatorClick={handleCalculatorClick}
            />
          ) : (
            <ViewSelfGuidedResourcePlanCard
              resourcePlan={resourcePlan}
              footerText="Working Plan"
              handleEdit={() => setIsEditingResourcePlan(true)}
              handleCopy={copiedResourcePlan ? () => setShowConfirmCopyAlert(true) : handleCopyPlan}
            />
          )}
        </div>
        <div className="2xl:hidden">
          <Tabs
            tabs={tabs}
            current={sideViewContent.view}
            handleChange={(tab) => setSideViewContent({ view: tab as typeof sideViewContent.view, data: null })}
          />
        </div>
        {sideViewContent.view === 'ai' && (
          <div className="2xl:w-1/2">
            <ChatCard
              resourcePlan={resourcePlan}
              history={assistantChatHistory}
              onMessage={handleNewMessage}
              handleRender={handleChatRender}
            />
          </div>
        )}
        {sideViewContent.view === 'calculator' && (
          <div className="2xl:w-1/2">
            <Card>
              <SalaryCalculator billRate={sideViewContent.data} />
            </Card>
          </div>
        )}
        {sideViewContent.view === 'compare' && (
          <div className="2xl:w-1/2">
            {copiedResourcePlan ? (
              <ViewSelfGuidedResourcePlanCard
                resourcePlan={copiedResourcePlan}
                footerText="Forecasted Plan"
                handleRevert={handleRevertToComparedPlan}
              />
            ) : (
              <Card>
                <div className="h-96 flex items-center justify-center text-center text-smet-dark-5">
                  To compare plans, click &#34;Done&#34; or &#34;Update&#34;, then &#34;Compare Plan&#34; below the
                  Working Plan.
                </div>
              </Card>
            )}
          </div>
        )}
        {showSavePlansModal && (
          <SaveResourcePlansModal
            projectName={project.project_name}
            resourcePlan={resourcePlan as resourcePlanType}
            copiedPlan={copiedResourcePlan as resourcePlanType}
            handleSave={saveResourcePlan}
            handleClose={() => setShowSavePlansModal(false)}
          />
        )}
        {showProjectSavedNotification && (
          <Notification
            title="Success!"
            text={`Project ${project.project_name} saved.`}
            handleClose={() => setShowProjectSavedNotification(false)}
          />
        )}
        {showConfirmCopyAlert && (
          <Alert open onClose={() => setShowConfirmCopyAlert(false)}>
            <AlertTitle>Are you sure?</AlertTitle>
            <AlertDescription>Your current Forecasted Plan will be overwritten.</AlertDescription>
            <AlertActions>
              <Button plain onClick={() => setShowConfirmCopyAlert(false)}>
                Cancel
              </Button>
              <Button className="smet-primary-button" onClick={handleCopyPlan}>
                Okay
              </Button>
            </AlertActions>
          </Alert>
        )}
      </div>
    </>
  )
}

export default SelfGuidedAssistant
