import { Button, Dialog, ZodForm } from '@/components'
import { Text } from '@/components/catalyst'
import { resourcePlanType } from '@/components/resource-plan/self/schemas'
import { SavePlansSchema, SavePlansType } from '@/utils/schemas'

const SaveResourcePlansModal = ({
  projectName,
  resourcePlan,
  copiedPlan,
  handleSave,
  handleClose
}: {
  projectName: string
  resourcePlan: resourcePlanType
  copiedPlan: resourcePlanType
  handleSave: (resourcePlan: resourcePlanType) => void
  handleClose: () => void
}) => {
  const savePlansFormFields = [
    {
      name: 'planType',
      label: '',
      config: {
        field: 'radio-group' as const,
        options: [
          {
            name: `${resourcePlan.name} [Working Plan]`,
            value: 'original'
          },
          {
            name: `${copiedPlan.name} [Forecasted Plan]`,
            value: 'copy'
          }
        ]
      }
    }
  ]

  const handleSavePlans = (data: SavePlansType) => {
    if (data.planType === 'original') {
      handleSave(resourcePlan)
    } else {
      handleSave(copiedPlan)
    }

    handleClose()
  }

  return (
    <Dialog
      open
      title={`Save ${projectName}`}
      actions={
        <>
          <Button outline onClick={handleClose}>
            Cancel
          </Button>
          <Button className="smet-primary-button" type="submit" form="save-plans-form">
            Save
          </Button>
        </>
      }
      onClose={handleClose}
    >
      <Text className="!text-smet-dark-2">You may only save one version of your plan to the project.</Text>
      <Text className="!text-smet-dark-2 mt-6">Which plan do you want to keep?</Text>
      <ZodForm
        id="save-plans-form"
        fields={savePlansFormFields}
        defaultValues={{ planType: undefined }}
        schema={SavePlansSchema}
        onSubmit={handleSavePlans}
      />
    </Dialog>
  )
}

export default SaveResourcePlansModal
