import { ChangeEvent, FocusEvent } from 'react'
import Slider from 'rc-slider'
import { Tooltip } from 'react-tooltip'

import { InformationCircleIcon } from '@heroicons/react/24/outline'

import { Field, Input, Label } from '@/components/catalyst'
import { ErrorMessage } from '@/components/catalyst/fieldset'

import { fields } from './lib/form'

const renderLabel = (label: { name: string; tooltip: string | null }) => {
  const { name, tooltip } = label

  if (tooltip) {
    return (
      <div className="flex items-center font-medium mb-3">
        <Label className="mr-1">{name}</Label>
        <InformationCircleIcon className="text-smet-blue h-4 w-4" data-tooltip-id={`${name}-tooltip`} />
        <Tooltip className="z-10" id={`${name}-tooltip`} opacity="1">
          <p className="max-w-xs">{tooltip}</p>
        </Tooltip>
      </div>
    )
  }

  return <Label>{name}</Label>
}

const Form = ({
  values,
  errors,
  handleChange,
  handleBlur,
  handleSliderBlur
}: {
  values: CalculatorFormValues
  errors: CalculatorFormValues
  handleChange: (e: ChangeEvent<HTMLInputElement>) => void
  handleBlur: (e: FocusEvent<HTMLInputElement>) => void
  handleSliderBlur: (e: FocusEvent<HTMLInputElement>) => void
}) => {
  return (
    <form className="my-4 space-y-4">
      {fields.map((field) => {
        const {
          name,
          label,
          constraints: { min, max, step }
        } = field

        const value = values[name as keyof typeof values]

        return (
          <Field key={name}>
            {renderLabel(label)}
            <Input id={name} name={name} value={value} onChange={handleChange} onBlur={handleBlur} />
            <Slider
              className="mt-2"
              min={min}
              max={max}
              step={step}
              value={Number(value) || 0}
              onChange={(val) => handleChange({ target: { name, value: String(val) } } as any)}
              onChangeComplete={(val) => handleSliderBlur({ target: { name, value: String(val) } } as any)}
              styles={{
                track: {
                  backgroundColor: '#0054e8'
                },
                handle: {
                  backgroundColor: '#0054e8',
                  borderColor: '#0054e8',
                  boxShadow: '0 0 0 5px #0054e8'
                }
              }}
            />
            {errors[name as keyof typeof errors] && (
              <ErrorMessage className="mt-2">{errors[name as keyof typeof errors]}</ErrorMessage>
            )}
          </Field>
        )
      })}
    </form>
  )
}

export default Form
