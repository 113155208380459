import { useState } from 'react'
import { useForm } from 'react-hook-form'

import { CheckIcon, PencilIcon } from '@heroicons/react/24/outline'
import { zodResolver } from '@hookform/resolvers/zod'

import { Field, Input, Text } from '@/components/catalyst'
import { useFetch } from '@/hooks'
import { CreateProjectSchema as EditProjectSchema, CreateProjectType as EditProjectType } from '@/utils/schemas'

const UpdateProjectForm = ({ project }: { project: Project }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { execute } = useFetch()

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors }
  } = useForm<EditProjectType>({
    defaultValues: {
      projectName: project.project_name,
      projectDescription: project.project_description
    },
    resolver: zodResolver(EditProjectSchema),
    mode: 'onChange'
  })

  const handleUpdate = async (data: EditProjectType) => {
    const { error } = await execute(`/api/projects/${project.id}`, 'PUT', {
      projectName: data.projectName,
      projectDescription: data.projectDescription
    })

    if (error) {
      alert(error)
    } else {
      setIsEditing(false)
    }
  }

  const { projectName, projectDescription } = getValues()

  return (
    <>
      {isEditing ? (
        <form
          id="update-project-form"
          className="flex flex-grow space-x-2 overflow-hidden self-end"
          onSubmit={handleSubmit((data: EditProjectType) => handleUpdate(data))}
        >
          <Field className="flex-shrink-0">
            <Input {...register('projectName')} invalid={!!errors?.projectName} />
          </Field>
          <Field className="flex-grow">
            <Input {...register('projectDescription')} invalid={!!errors?.projectDescription} />
          </Field>
          <button className="flex-shrink-0" type="submit" form="update-project-form">
            <CheckIcon className="h-5 w-5 cursor-pointer text-smet-secondary-text hover:text-smet-blue" />
          </button>
        </form>
      ) : (
        <div className="flex space-x-2 overflow-hidden self-end">
          <div className="font-semibold flex-shrink-0">{projectName}</div>
          <Text className="truncate flex-grow">{projectDescription}</Text>
          <PencilIcon
            className="h-5 w-5 cursor-pointer text-smet-secondary-text hover:text-smet-blue flex-shrink-0"
            onClick={() => setIsEditing(true)}
          />
        </div>
      )}
    </>
  )
}

export default UpdateProjectForm
