'use client'

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'
import { getCurrentUser, fetchUserAttributes, AuthUser, FetchUserAttributesOutput } from 'aws-amplify/auth'

interface CognitoUser {
  user: AuthUser
  attributes: FetchUserAttributesOutput
}

export interface CompanyProfile {
  tenant_id: string
  tenant_email: string
  tenant_name: string
  tenant_industry: string
  tenant_location: string
  tenant_description: string
}

interface UserContextType {
  user: CognitoUser | null
  companyProfile: CompanyProfile | null
  loading: boolean
  setUser: (user: CognitoUser | null) => void
  setCompanyProfile: (profile: CompanyProfile | null) => void
  clearCompanyProfile: () => void
  clearUser: () => void
}

const UserContext = createContext<UserContextType | undefined>(undefined)

export const UserProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<CognitoUser | null>(null)
  const [companyProfile, setCompanyProfile] = useState<CompanyProfile | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const fetchUser = async () => {
    try {
      const user = await getCurrentUser()
      const userAttributes = await fetchUserAttributes()

      setUser({
        user,
        attributes: userAttributes
      })

      if (userAttributes['custom:accountType'] === 'co') {
        const res = await fetch('/api/company-profile', {
          method: 'GET'
        })

        if (res.ok) {
          res.json().then((json) => {
            setCompanyProfile(json.data.profile)
          })
        }
      }
    } catch (error) {
      setUser(null)
      setCompanyProfile(null)
    } finally {
      setLoading(false)
    }
  }

  const clearUser = () => {
    setUser(null)
  }

  const clearCompanyProfile = () => {
    setCompanyProfile(null)
  }

  useEffect(() => {
    fetchUser()
  }, [])

  return (
    <UserContext.Provider
      value={{ user, companyProfile, loading, setCompanyProfile, setUser, clearCompanyProfile, clearUser }}
    >
      {children}
    </UserContext.Provider>
  )
}

export const useUser = () => {
  const context = useContext(UserContext)

  if (!context) {
    throw new Error('useUser must be used within a UserProvider')
  }

  return context
}
