import { FieldErrors, UseFieldArrayRemove, UseFormRegister } from 'react-hook-form'

import { MinusCircleIcon } from '@heroicons/react/24/solid'
import { CalculatorIcon } from '@heroicons/react/24/outline'

import { Combobox, usePersonnel } from '@/components'
import {
  Field,
  Input,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow
} from '@/components/catalyst'
import { formatPercent } from '@/components/salary-calculator/lib/numbro'

import { TABLE_CONFIG } from './config'
import { resourcePlanType, resourceType } from './schemas'

import '../ResourcePlan.css'

interface Values {
  totalBillRate: number
  totalSalary: number
  totalFullyBurdenedRate: number
  totalRevenue: number
  totalHourlyProfit: number
  totalProfit: number
}

const defaultValues: Values = {
  totalBillRate: 0,
  totalSalary: 0,
  totalFullyBurdenedRate: 0,
  totalRevenue: 0,
  totalHourlyProfit: 0,
  totalProfit: 0
}

const renderTableHead = (
  fields: { id: string }[],
  values: resourceType[],
  remove: UseFieldArrayRemove,
  onCalculatorClick: (value: string) => void
) => {
  const deleteColumnCells = fields.map((field, index) => {
    return fields.length > 1 ? (
      <TableHeader key={field.id}>
        <div className="flex justify-center space-x-4">
          <CalculatorIcon
            className="cursor-pointer h-6 w-6"
            onClick={() => onCalculatorClick(values[index].billRate.toString())}
          />
          <MinusCircleIcon
            key={field.id}
            className="text-smet-red cursor-pointer h-6 w-6"
            onClick={() => remove(index)}
          />
        </div>
      </TableHeader>
    ) : (
      <TableHeader key={field.id}>
        <CalculatorIcon
          className="cursor-pointer h-6 w-6 m-auto"
          onClick={() => onCalculatorClick(values[0].billRate.toString())}
        />
      </TableHeader>
    )
  })

  return (
    <TableHead>
      <TableRow>
        <TableHeader className="w-32" />
        <TableHeader />
        {deleteColumnCells}
      </TableRow>
    </TableHead>
  )
}

const renderTableBody = (
  values: resourceType[],
  totals: Values,
  rowValues: Values[],
  errors: FieldErrors<resourcePlanType>,
  personnel: { name: string }[],
  register: UseFormRegister<resourcePlanType>
) => {
  const body = TABLE_CONFIG.map((config) => {
    const { label, key, aggregateKey, field, compute, format } = config

    return (
      <TableRow key={label}>
        <TableCell className="font-bold">{label}</TableCell>
        <TableCell className="font-bold">
          {aggregateKey ? format(totals[aggregateKey as keyof typeof totals]) : ''}
        </TableCell>
        {values.map((resource, index) => {
          if (key) {
            let input

            if (field.type === 'text') {
              input = (
                <Input
                  type="text"
                  {...register(`resourcePlan.${index}.${key}` as any)}
                  invalid={!!errors?.resourcePlan?.[index]?.[key as keyof (typeof errors.resourcePlan)[typeof index]]}
                />
              )
            } else if (field.type === 'number') {
              input = (
                <Input
                  type="number"
                  {...register(`resourcePlan.${index}.${key}` as any, {
                    setValueAs: (value: string) => parseFloat(value)
                  })}
                  invalid={!!errors?.resourcePlan?.[index]?.[key as keyof (typeof errors.resourcePlan)[typeof index]]}
                  onKeyDown={(e) => {
                    if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                      e.preventDefault()
                    }
                  }}
                  onWheel={(e) => {
                    e.preventDefault()
                    e.currentTarget.blur()
                  }}
                />
              )
            } else if (field.type === 'select') {
              input = (
                <Select
                  {...register(`resourcePlan.${index}.${key}` as any)}
                  invalid={!!errors?.resourcePlan?.[index]?.[key as keyof (typeof errors.resourcePlan)[typeof index]]}
                >
                  {field.options?.map((option) => {
                    return (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    )
                  })}
                </Select>
              )
            } else {
              input = (
                <Combobox
                  {...register(`resourcePlan.${index}.${key}` as any)}
                  options={personnel}
                  value={{ name: resource[key as keyof typeof resource] as string }}
                  invalid={!!errors?.resourcePlan?.[index]?.[key as keyof (typeof errors.resourcePlan)[typeof index]]}
                />
              )
            }

            return (
              <TableCell key={`${label}-${index}`} className="min-w-52">
                <Field>{input}</Field>
              </TableCell>
            )
          }

          return (
            <TableCell key={`resource-${index}`}>
              {format
                ? format(compute ? compute(resource) : Number(resource[key as keyof typeof resource]))
                : resource[key as keyof typeof resource]}
            </TableCell>
          )
        })}
      </TableRow>
    )
  })

  return (
    <TableBody>
      {body}
      <TableRow className="!bg-smet-green-light-6">
        <TableCell className="font-bold !bg-smet-green-light-6">Profit Margin</TableCell>
        <TableCell className="font-bold !bg-smet-green-light-6">
          {formatPercent(totals.totalProfit / totals.totalRevenue || 0)}
        </TableCell>
        {values.map((value, index) => {
          return (
            <TableCell key={`resource-${index}`}>
              {formatPercent(rowValues[index].totalProfit / rowValues[index].totalRevenue || 0)}
            </TableCell>
          )
        })}
      </TableRow>
    </TableBody>
  )
}

const CreateSelfGuidedResourcePlanTable = ({
  fields,
  values,
  errors,
  register,
  remove,
  onCalculatorClick
}: {
  fields: { id: string }[]
  values: resourceType[]
  errors: FieldErrors<resourcePlanType>
  register: UseFormRegister<resourcePlanType>
  remove: UseFieldArrayRemove
  onCalculatorClick: (value: string) => void
}) => {
  const { personnel } = usePersonnel()

  const rowValues: Values[] = Array(fields.length)
    .fill(null)
    .map(() => ({ ...defaultValues }))

  const tableTotals: Values = { ...defaultValues }

  TABLE_CONFIG.map((config) => {
    const { key, aggregateKey, compute } = config

    if (aggregateKey) {
      values.forEach((resource, index) => {
        const value = compute ? compute(resource) : Number(resource[key as keyof typeof resource])

        tableTotals[aggregateKey as keyof typeof tableTotals] += value

        rowValues[index][aggregateKey as keyof Values] = value
      })
    }
  })

  return (
    <div className="flex flex-col">
      <Table className="self-guided-resource-plan-table" grid dense>
        {renderTableHead(fields, values, remove, onCalculatorClick)}
        {renderTableBody(values, tableTotals, rowValues, errors, personnel, register)}
      </Table>
    </div>
  )
}

export default CreateSelfGuidedResourcePlanTable
