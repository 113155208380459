import { ReactNode } from 'react'

import {
  Dialog as CatalystDialog,
  DialogActions,
  DialogBody,
  DialogDescription,
  DialogTitle,
  Divider
} from '@/components/catalyst'

const Dialog = ({
  title,
  description,
  size,
  bgColor,
  actions,
  open,
  children,
  onClose
}: {
  title?: string
  description?: string
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | '4xl' | '5xl' | undefined
  bgColor?: string
  actions?: ReactNode
  open?: boolean
  children: ReactNode
  onClose: () => void
}) => {
  return (
    <CatalystDialog className={bgColor} open={open} size={size} onClose={onClose}>
      {title && <DialogTitle className="text-center !text-smet-dark-2 !text-2xl">{title}</DialogTitle>}
      {description && <DialogDescription>{description}</DialogDescription>}
      {title && <Divider className="my-2" />}
      <DialogBody>{children}</DialogBody>
      {actions && <DialogActions>{actions}</DialogActions>}
    </CatalystDialog>
  )
}

export default Dialog
