'use client'

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'

import { useUser } from './UserContextProvider'

interface NotificationsContextType {
  notifications: boolean
  loading: boolean
  refreshNotifications: () => void
}

const NotificationsContext = createContext<NotificationsContextType | undefined>(undefined)

export const NotificationsProvider = ({ children }: { children: ReactNode }) => {
  const [notifications, setNotifications] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(true)

  const { user } = useUser()

  const fetchNotifications = async () => {
    setLoading(true)

    try {
      const res = await fetch('/api/notifications/feedback', {
        method: 'GET'
      })

      if (res.ok) {
        res.json().then((json) => setNotifications(json.data.unresolved || false))
      }
    } catch (error) {
      setNotifications(false)
    } finally {
      setLoading(false)
    }
  }

  const refreshNotifications = () => {
    fetchNotifications()
  }

  useEffect(() => {
    fetchNotifications()
  }, [user])

  return (
    <NotificationsContext.Provider value={{ notifications, loading, refreshNotifications }}>
      {children}
    </NotificationsContext.Provider>
  )
}

export const useNotifications = () => {
  const context = useContext(NotificationsContext)

  if (!context) {
    throw new Error('useNotifications must be used within a NotificationsProvider')
  }

  return context
}
