'use client'

import { useEffect } from 'react'
import { useRouter } from 'next/navigation'

import { useUnsavedChanges } from '@/components'

const useWarnOnRouteChange = (confirmMessage?: string) => {
  const router = useRouter()
  const { hasUnsavedChanges } = useUnsavedChanges()

  const message = confirmMessage || 'You have unsaved changes. Are you sure you want to leave?'

  useEffect(() => {
    const originalPush = router.push
    const originalReplace = router.replace

    const confirmNavigation = (url: string, options?: any) => {
      if (!hasUnsavedChanges || confirm(message)) {
        return originalPush(url, options)
      }
    }

    router.push = confirmNavigation as typeof router.push
    router.replace = confirmNavigation as typeof router.replace

    return () => {
      router.push = originalPush
      router.replace = originalReplace
    }
  }, [hasUnsavedChanges, router])
}

export default useWarnOnRouteChange
