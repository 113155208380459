import Link from 'next/link'

import { PlusCircleIcon } from '@heroicons/react/16/solid'

import { Button } from '@/components'
import { Subheading, Text } from '@/components/catalyst'

const DashboardCard = ({
  subheading,
  text,
  href = '',
  Icon,
  disabled = false,
  onClick
}: {
  subheading: string
  text: string
  href?: string
  Icon: React.ComponentType<React.SVGProps<SVGSVGElement>>
  disabled?: boolean
  onClick?: () => void
}) => {
  const cardBg = disabled ? 'bg-smet-gray' : 'bg-smet-blue-light-5'

  return (
    <div className={`flex flex-col justify-between border rounded-lg border-smet-blue-light-3 ${cardBg}`}>
      <div className="flex flex-col items-center justify-center text-center space-y-2 max-w-36 m-auto py-8">
        <Icon className="h-8 w-8 text-smet-blue" />
        <Subheading className="!text-smet-blue-dark">{subheading}</Subheading>
        <Text className="!text-smet-blue-dark !leading-tight">{text}</Text>
      </div>
      <div className="flex justify-center mb-8">
        {disabled ? (
          <Button className="flex justify-center items-center smet-inverted-button smet-disabled-button">
            <span>Coming Soon</span>
          </Button>
        ) : href ? (
          <Link href={href}>
            <Button className="flex justify-center items-center smet-inverted-button">
              <span>New Project</span>
              <PlusCircleIcon className="h-4 w-4 ml-1" />
            </Button>
          </Link>
        ) : (
          <Button className="flex justify-center items-center smet-inverted-button" onClick={onClick}>
            <span>New Project</span>
            <PlusCircleIcon className="h-4 w-4 ml-1" />
          </Button>
        )}
      </div>
    </div>
  )
}

export default DashboardCard
