'use client'

import { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'

import { CheckIcon, NoSymbolIcon, PlusIcon } from '@heroicons/react/16/solid'

import { zodResolver } from '@hookform/resolvers/zod'

import { Button, Card, CreateSelfGuidedResourcePlanTable } from '@/components'
import { Field, Input, Text } from '@/components/catalyst'

import { resourcePlanType, ResourcePlanSchema } from './schemas'

const defaultResourcePlan: resourcePlanType = {
  resourcePlan: [
    {
      name: '',
      role: 'Engineer',
      roleType: 'Salaried',
      billRate: 0,
      billableHours: 1920,
      salary: 0,
      fullyBurdenedRate: 0
    }
  ],
  name: 'Resource Plan',
  startDate: '',
  endDate: ''
}

const CreateSelfGuidedResourcePlanCard = forwardRef(
  (
    {
      resourcePlan = { ...defaultResourcePlan },
      primaryButtonText = 'Done',
      footerText = '',
      handleDone,
      handleSave,
      handleChange,
      handleCancel,
      handleDirty,
      onCalculatorClick
    }: {
      resourcePlan?: resourcePlanType
      primaryButtonText?: string
      footerText?: string
      handleDone?: (resourcePlan: resourcePlanType) => void
      handleSave?: (resourcePlan: resourcePlanType) => void
      handleChange?: (resourcePlan: resourcePlanType) => void
      handleCancel?: () => void
      handleDirty?: (isDirty: boolean) => void
      onCalculatorClick: (value: string) => void
    },
    ref
  ) => {
    const {
      control,
      register,
      handleSubmit,
      getValues,
      watch,
      reset,
      formState: { errors, isDirty }
    } = useForm<resourcePlanType>({
      defaultValues: resourcePlan,
      resolver: zodResolver(ResourcePlanSchema),
      mode: 'onChange'
    })

    const { fields, append, remove } = useFieldArray({
      control,
      name: 'resourcePlan'
    })

    useImperativeHandle(ref, () => ({
      handleSubmit,
      reset
    }))

    const handleAppendRow = () => {
      const { resourcePlan } = getValues()
      append(resourcePlan[resourcePlan.length - 1])
    }

    const values = watch()

    useEffect(() => {
      if (handleChange) {
        handleChange(values)
      }
    }, [JSON.stringify(values), handleChange])

    useEffect(() => {
      handleDirty?.(isDirty)
    }, [isDirty])

    return (
      <Card
        title={
          <div className="space-y-2 sm:flex sm:justify-between sm:space-y-0">
            <div>
              <Field>
                <Input {...register('name')} invalid={!!errors?.name} />
              </Field>
            </div>
            <div className="flex justify-between items-center space-x-2">
              <Field>
                <Input {...register('startDate')} type="date" invalid={!!errors?.startDate} />
              </Field>
              <span>to</span>
              <Field>
                <Input {...register('endDate')} type="date" invalid={!!errors?.endDate} />
              </Field>
            </div>
          </div>
        }
        footer={
          <div className="flex justify-between items-center">
            <Text>{footerText}</Text>
            <div className="flex items-center justify-center space-x-2">
              {handleCancel && (
                <Button plain onClick={handleCancel}>
                  Cancel
                </Button>
              )}
              {handleSave && (
                <Button
                  className="smet-primary-button sm:h-auto sm:w-auto"
                  onClick={handleSubmit((resourcePlan: resourcePlanType) => handleSave(resourcePlan))}
                >
                  <span>Save</span>
                  <svg
                    className="h-5 w-5 sm:h-4 sm:w-4"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="white"
                  >
                    <path d="M18 2.25c.2 0 .39.08.53.22l3 3c.14.14.22.33.22.53v15c0 .41-.34.75-.75.75H3a.75.75 0 0 1-.75-.75V3c0-.41.34-.75.75-.75h15ZM17 12H7a1 1 0 0 0-1 1v7.25h12V13a1 1 0 0 0-1-1Zm-.5-8.25h-9V8a1 1 0 0 0 1 1h7a1 1 0 0 0 1-1V3.75Zm-2.25.75c.41 0 .75.34.75.75v1.5a.75.75 0 1 1-1.5 0v-1.5c0-.41.34-.75.75-.75Z" />
                  </svg>
                </Button>
              )}
              {handleDone && (
                <Button
                  className="smet-primary-button"
                  onClick={handleSubmit((resourcePlan: resourcePlanType) => handleDone(resourcePlan))}
                >
                  <span>{primaryButtonText}</span> <CheckIcon className="h-4 w-4 ml-1" />
                </Button>
              )}
            </div>
          </div>
        }
      >
        <div className="flex items-center justify-center bg-smet-gray border border-smet-stroke p-2 space-x-2 mb-2">
          <Button
            className="flex items-center justify-center !text-smet-dark-5"
            plain
            onClick={() => reset({ ...defaultResourcePlan })}
          >
            Clear Values <NoSymbolIcon className="h-4 w-4 ml-1 fill-smet-dark-5" />
          </Button>
          <Button
            className="flex items-center justify-center !text-smet-dark-5"
            plain
            disabled={fields.length >= 5}
            onClick={handleAppendRow}
          >
            Add Employee <PlusIcon className="h-4 w-4 ml-1" />
          </Button>
        </div>
        <CreateSelfGuidedResourcePlanTable
          fields={fields}
          values={values.resourcePlan}
          errors={errors}
          register={register}
          remove={remove}
          onCalculatorClick={onCalculatorClick}
        />
      </Card>
    )
  }
)

CreateSelfGuidedResourcePlanCard.displayName = 'CreateSelfGuidedResourcePlanCard'

export default CreateSelfGuidedResourcePlanCard
