import * as Yup from 'yup'

const PROPOSED_SALARY_MIN = 50000
const PROPOSED_SALARY_MAX = 500000
const PROPOSED_SALARY_STEP = 500

const WRAP_RATE_MIN = 1.0
const WRAP_RATE_MAX = 2.0
const WRAP_RATE_STEP = 0.01

const BILL_RATE_MIN = 0
const BILL_RATE_MAX = 1000
const BILL_RATE_STEP = 0.5

export const fields = [
  {
    name: 'billRate',
    label: {
      name: 'Bill Rate',
      tooltip: 'The amount a company or professional charges per hour of work.'
    },
    constraints: {
      min: BILL_RATE_MIN,
      max: BILL_RATE_MAX,
      step: BILL_RATE_STEP
    }
  },
  {
    name: 'wrapRate',
    label: {
      name: 'Wrap Rate',
      tooltip:
        "A wrap rate is the total cost of labor including indirect expenses and profit, used to determine what you charge for an hour of work. It's calculated by dividing the total cost by the base hourly rate, typically ranging between 1 and 2."
    },
    constraints: {
      min: WRAP_RATE_MIN,
      max: WRAP_RATE_MAX,
      step: WRAP_RATE_STEP
    }
  },
  {
    name: 'proposedSalary',
    label: {
      name: 'Proposed Salary',
      tooltip: null
    },
    constraints: {
      min: PROPOSED_SALARY_MIN,
      max: PROPOSED_SALARY_MAX,
      step: PROPOSED_SALARY_STEP
    }
  }
]

export const validations = {
  wrapRate: Yup.number()
    .typeError('Wrap Rate must be a valid number')
    .required('Wrap Rate is required')
    .min(WRAP_RATE_MIN, 'Wrap Rate minimum is 1.00')
    .max(WRAP_RATE_MAX, 'Wrap Rate maximum is 2.00'),
  billRate: Yup.number()
    .typeError('Bill Rate must be a valid number')
    .positive('Bill Rate must be a valid number')
    .required('Bill Rate is required')
    .max(BILL_RATE_MAX, 'Bill Rate maximum is 1,000'),
  proposedSalary: Yup.number()
    .typeError('Salary must be a valid number')
    .required('Salary is required')
    .min(PROPOSED_SALARY_MIN, 'Salary minimum is 50,000')
    .max(PROPOSED_SALARY_MAX, 'Salary maximum is 500,000')
}
