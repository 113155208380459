'use client'

import React, { createContext, useContext, useState, useEffect, ReactNode } from 'react'

import { useUser } from './UserContextProvider'

interface PersonnelContextType {
  personnel: { name: string }[]
  loading: boolean
  refreshPersonnel: () => void
  clearPersonnel: () => void
}

const PersonnelContext = createContext<PersonnelContextType | undefined>(undefined)

export const PersonnelProvider = ({ children }: { children: ReactNode }) => {
  const [personnel, setPersonnel] = useState<{ name: string }[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { user } = useUser()

  const fetchPersonnel = async () => {
    setLoading(true)

    try {
      const res = await fetch('/api/personnel', {
        method: 'GET'
      })

      if (res.ok) {
        res.json().then((json) => {
          setPersonnel(json.data.personnel)
        })
      }
    } catch (error) {
      setPersonnel([])
    } finally {
      setLoading(false)
    }
  }

  const refreshPersonnel = () => {
    fetchPersonnel()
  }

  const clearPersonnel = () => {
    setPersonnel([])
  }

  useEffect(() => {
    fetchPersonnel()
  }, [user])

  return (
    <PersonnelContext.Provider value={{ personnel, loading, refreshPersonnel, clearPersonnel }}>
      {children}
    </PersonnelContext.Provider>
  )
}

export const usePersonnel = () => {
  const context = useContext(PersonnelContext)

  if (!context) {
    throw new Error('usePersonnel must be used within a PersonnelProvider')
  }

  return context
}
