'use client'

import { useState } from 'react'

const useFetch = () => {
  const [loading, setLoading] = useState(false)

  const execute = async (url: string, method: 'GET' | 'POST' | 'PUT' | 'DELETE', body?: Record<string, any>) => {
    setLoading(true)

    try {
      const res = await fetch(url, {
        method,
        headers: {
          'Content-Type': 'application/json'
        },
        body: body ? JSON.stringify(body) : undefined
      })

      if (res.ok) {
        const json = await res.json()

        return { data: json.data, error: null }
      } else {
        throw new Error(`${res.status} (${res.statusText})`)
      }
    } catch (err) {
      const errorMessage = err instanceof Error ? err.message : String(err)

      return { data: null, error: errorMessage }
    } finally {
      setLoading(false)
    }
  }

  return { loading, execute }
}

export default useFetch
