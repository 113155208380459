'use client'

import { ReactNode, useEffect, useState } from 'react'
import { usePathname } from 'next/navigation'
import { signOut } from 'aws-amplify/auth'

import { ChatBubbleLeftEllipsisIcon } from '@heroicons/react/16/solid'

import { ClipLoader, useNotifications, useUser } from '@/components'

import SidebarNav from './SidebarNav'

const items = [
  {
    label: 'Requests',
    href: '/requested-feedback',
    icon: <ChatBubbleLeftEllipsisIcon />,
    notifications: true
  }
]

const BusinessAdvisorNav = ({ children }: { children: ReactNode }) => {
  const [hasUnresolvedFeedback, setHasUnresolvedFeedback] = useState<boolean>(false)

  const { loading: notificationsLoading, notifications } = useNotifications()
  const { loading: userLoading, clearUser } = useUser()
  const pathname = usePathname()

  const logout = async () => {
    try {
      await signOut()
      clearUser()
      window.location.replace('/auth/login')
    } catch (error) {
      alert(error)
    }
  }

  useEffect(() => {
    if (!userLoading && !notificationsLoading) {
      setHasUnresolvedFeedback(notifications)
    }
  }, [userLoading, notificationsLoading, notifications])

  if (userLoading || notificationsLoading) {
    return (
      <div className="flex h-screen items-center justify-center">
        <ClipLoader />
      </div>
    )
  }

  return (
    <SidebarNav
      homePath="/ba-dashboard"
      currentPath={pathname}
      sidebarItems={items}
      notifications={hasUnresolvedFeedback}
      logout={logout}
    >
      {children}
    </SidebarNav>
  )
}

export default BusinessAdvisorNav
